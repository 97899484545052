.app-header-container {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 12px;
  border-bottom: 0.5px solid var(--line);
}

.app-header-contents {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


.app-header-app-name {
  font-weight: bold;
}

.app-header-app-name-main {
  font-size: 24px;
}

.app-header-app-name-sub {
  margin-left: 10px;
  font-size: 12px;
}

.app-header-buttons {
  display: flex;
  align-items: center;
}

.app-header-icon-user {
  width: 30px;
  height: 30px;
}

.app-header-icon-home {
  margin-left: 10px;
  width: 35px;
  height: 35px;
}
