:root {
  --appBackground: #F4F4F4;
  --line: #D8D8D8;
  --darkerGray: #ECECEC;
  --green: #238700;
  --textGray: #484848;
  --red: #ea6c6c;
}


input:focus {
  outline: none;
}

.App {
  width: 100vw;
}

* {
  font-family: "Roboto", sans-serif;
}

a {
  display: block;
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}



body {
  margin: 0;
  background-color: var(--appBackground);
  padding-bottom: 70px;
}


.app-container {
  /* margin: 0 auto; */
}

.contents-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.page-container {
  margin-top: 80px;
  padding: 15px 0;
}

.page-section {
  margin: 30px 0;
}


.element-container {
  padding: 20px;
}

.home-header {
  margin-bottom: 17px;
}

.home-sub-header {
  margin-bottom: 15px;
}

.header-title {
  font-size: 22px;
  font-weight: bold;
}

.header-sub-title {
  font-size: 18px;
  font-weight: bold;
}

.button {
  margin: 12px 0;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.button-black {
  background-color: black;
  color: white;
}

.button-skelton {
  background-color: transparent;
  color: black;
  border: 1px solid black;
}


.default-input {
  width: calc(100% - 10px * 2);
  padding: 10px;
  border: none;
  background-color: white;
  border-radius: 5px;
}













/*  */
