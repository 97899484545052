

@media only screen and (min-width: 800px) {
  .flex-wrapper {
    display: flex;
    /* flex-wrap: wrap; */
  }

  .flex-item:first-child {
    margin-right: 50px;
    width: 40%;
  }

  .home-today-section {
    /* min-width: 500px; */
    width: 60%;
  }
}

.home-refresh-container {
  padding: 10px 0;
  background-color: var(--darkerGray);
  text-align: center;
}

.home-refresh-container span {
  font-weight: bold;
  font-size: 14px;
}

.home-time-container {
  background-color: white;
  border-radius: 20px;
  margin-top: 13px
}

.home-time-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-time-contents p {
  font-weight: bold;
}

.home-time-day-of-week {
  font-size: 22px;
}

.home-time-month-year {
  font-size: 18px;
}

.home-time-time {
  font-size: 48px;
}


.home-header-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-header-message-buttons {
  display: flex;
  align-items: center;
}

.home-header-message-delete-button {
  margin-right: 20px;
}

.home-header-message-delete-button span {
  font-weight: bold;
  color: var(--line);
  font-size: 14px;
}

.home-header-message-save-button {
  padding: 6px 18px;
  border: 1px solid black;
  border-radius: 3px;
}

.home-header-message-save-button span {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 17px;
}

.home-message-content-input {
  width: calc(100% - 20px * 2);
  border: none;
  border-radius: 20px;
  font-size: 14px;
  line-height: 22px;
  resize: none;
  min-height: 80px;
}

.home-friend-status-section {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid var(--line);
  border-radius: 10px;
}

.home-friend-status-no-commitment-message {
  font-size: 14px;
  color: var(--line);
  font-weight: bold;
}

.home-friend-status-name {
  font-size: 14px;
  color: var(--line);
  font-weight: bold;
}

.home-friend-status-status {
  font-size: 14px;
  color: #979797;
  font-weight: bold;
}

.home-friend-status-completion, .home-friend-status-time {
  font-size: 14px;
  color: var(--line);
}

.home-friend-status-comment-description {
  color: var(--line);
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

.home-friend-status-comment {
  margin-top: 10px;
  background-color: #F9F9F9;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid var(--line);

  font-size: 14px;
  color: var(--textGray);
}

.home-status-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-status-button {
  position: relative;
  width: 73px;
  height: 73px;
  border-radius: 27px;
}

.home-status-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-status-texts {
  margin-top: 7px;
  text-align: center;
}

.home-status-text-name {
  font-size: 14px;
  font-weight: bold
}

.home-status-text-time {
  font-size: 12px;
}

.home-status-section-gray .home-status-button {
  border: 1px solid var(--line);
}

.home-status-section-gray .home-status-texts {
  color: var(--line);
}

.home-status-section-black .home-status-button {
  border: 1px solid black;
  background-color: black;
}

.home-status-section-black .home-status-texts {
  color: black;
}

.home-today-content {
  background-color: white;
  border-radius: 20px;
}

.home-today-content-top {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px 20px 0 0;
}

.home-today-content-line {
  height: 30px;
  width: 1px;
  background-color: var(--line);
  margin: 0 30px;
  margin-left: auto;
}

.home-today-content-right {

}

.line {
  width: 100%;
  height: 1px;
  background-color: var(--line);
}

.home-today-content-bottom {
  background-color: white;
  border-radius: 0 0 20px 20px;
}

.home-today-task-section {
  display: flex;
  align-items: center;
}

.home-today-task-number {
  font-weight: bold;
  font-size: 32px;
}

.home-today-task-text {
  margin-left: 12px;
  font-weight: bold;
  font-size: 14px;
}

.home-today-task-delay {
  /* width: fit-content; */
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;

  font-size: 12px;
}

.home-today-task-delay.good {
  border: 3px solid #78d29a;
  color: #78d29a;
}

.home-today-task-delay.bad {
  border: 3px solid #d27878;
  color: #d27878;
}

.home-today-task-delay span {
  display: block;
}

.home-today-task-delay-number {
  font-size: 22px;
}

.home-today-task-delay-letters {

}

.home-today-task-delay-message {
  display: block;
  margin-top: 3px;
  font-weight: bold;
  font-size: 12px;
  color: var(--textGray);
}

.home-today-hours-text {
  font-size: 14px;
  font-weight: bold;
}

.home-today-hours-row {
  display: flex;
  align-items: baseline;
}

.home-today-hours-current {
  font-weight: bold;
  font-size: 32px;
}

.home-today-hours-remainig {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}

.home-todya-hours-percent {
  margin-top: 5px;
  font-weight: bold;
  color: var(--textGray);
}

.home-today-view-button {
  margin-top: 20px;
}

.home-today-empty-mesage-wrapper {
  color: var(--line);
  margin-top: 10px;
  text-align: center;
}

.home-status-content-center {
  text-align: center;
}


.home-status-message {
  font-size: 14px;
  color: var(--line);
  text-align: center;
}









/*  */
