

.login-form {
  margin: 30px 0;
}

.login-form-title {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 9px;
}

.login-form-input {
  display: block;
  border: none;
  border-radius: 5px;
  width: calc(100% - 10px * 2);
  padding: 10px;

  font-size: 14px;
  font-weight: bold;
  color: var(--textGray);
}

.login-buttons-section {
  margin-top: 50px;
}

.login-button {

}

.login-switch-section {
  text-align: center;
  margin-top: 25px;
  color: var(--line);
  font-size: 16px;
}

.login-switch-button {
  margin-top: 5px;
  text-decoration: underline;
}
