.create-commitment-workinghours-text {
  font-size: 18px;
  font-weight: bold;
}

.create-commitment-workinghours-number-section {
  margin-top: 12px;
  display: flex;
  align-items: baseline;
}

.create-commitment-workinghours-number {
  border: none;
  width: calc(120px - 10px * 2);
  padding: 10px;
  font-size: 42px;
  font-weight: bold;
  border-radius: 15px;
  text-align: center;
}

.create-commitment-workinghours-number-text {
  font-size: 18px;
  font-weight: bold;
  margin-left: 16px;
}

.create-commitment-notion-description {
  width: calc(100% - 20px * 2);
  background-color: white;
  border-radius: 5px;

  display: flex;
  align-items: center;
  padding: 20px;
}

.create-commitment-notion-description-text {
  margin-left: 20px;
  font-size: 16px;
  color: #979797;
}

.create-commitment-tasks-header-section {
  display: flex;
  align-items: baseline;
}

.create-commitment-tasks-sub-header {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: var(--line);
}

.create-commitment-task-card {
  margin: 12px 0;
  background-color: white;
  border-radius: 5px;

  display: flex;
  align-items: center;
}

.create-commitment-task-card-line {
  height: 40px;
  width: .5px;
  background-color: var(--line);
  margin: 0 20px;
}

.create-commitment-task-card-title {
  width: 70%;

  font-size: 12px;
  font-weight: bold;
}

.create-commitment-task-card-status {
  width: 30%;
  text-align: center;
}

.create-commitment-task-card-status-green {
  color: var(--green);
}

.create-commitment-task-card-gray {
  color: var(--line);
}

.create-commitment-task-card-status-text {
  font-size: 12px;
  font-weight: bold;
}

.create-commitment-save-section {
  text-align: center;
}

.create-commitment-save-description {
  display: block;
  margin: 20px 0;
  color: var(--line);
}










/*  */
