.user-setting-section {
  margin: 30px 0;
}

.user-setting-form {
  margin: 15px 0;
}

.user-setting-form-label {
  display: block;
  font-weight: bold;
  font-size: 14px;
}

.user-setting-form-lable-small {
  font-size: 10px;
  color: var(--line);
}

.user-setting-input {
  margin-top: 10px;
}
