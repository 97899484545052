
.signup-form-name input {
  border: 2px solid black;
}

.signup-error-section {
  margin: 0 auto;
  text-align: center;
  width: 80%;
}

.signup-error-message {
  color: var(--red);
  font-size: 14px;
  font-weight: bold;
}
